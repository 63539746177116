import React, { useState } from "react"
import Sidebar from "./sidebar/Sidebar"
import Content from "./Content"
import "./style.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
export const Extension = () => {
  const [pageId, setPageId] = useState()
  return (
    <Router>
      <Switch>
        <div className="main">
          <Sidebar pageId={pageId} setPageId={setPageId} />
          <Route exact path="/content">
            <Content pageId={pageId} />
          </Route>
        </div>
      </Switch>
    </Router>
  )
}
