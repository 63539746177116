import React, { useEffect } from "react"
import { Extension } from "./component/Extension"
import { getAuth, signInWithCustomToken } from "firebase/auth"
const getUrlVars = () => {
  var vars = [],
    hash
  var hashes = window.location.href
    .slice(window.location.href.indexOf("?") + 1)
    .split("&")
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=")
    vars.push(hash[0])
    vars[hash[0]] = hash[1]
  }
  return vars
}

const App = () => {
  useEffect(() => {
    let url = getUrlVars()
    let auth = getAuth()
    signInWithCustomToken(auth, url.auth)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user
        console.log("Useree", user)
        // ...
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        console.log("Errr", error)
        // ...
      })

    let extTokenAuth = {
      token: url.token,
      room: url.room,
      auth: url.auth,
      extensionID: url.extensionID,
    }
    let data = {
      web_desktop_layout: [
        {
          type: "stage",
          height: 100,
          width: 33,
          top: 0,
          left: 0,
          opacity: 1,
        },
        {
          type: "iframe1",
          webpage_url: "abc.com",
          height: 100,
          width: 67,
          top: 0,
          left: 33,
          opacity: 1,
        },
        {
          type: "iframe2",
          webpage_url: "abc.com",
          height: 100,
          width: 67,
          top: 0,
          left: 33,
          opacity: 1,
        },
        {
          type: "iframe3",
          webpage_url: "abc.com",
          height: 100,
          width: 67,
          top: 0,
          left: 33,
          opacity: 1,
        },
      ],
      mobile_portrait_layout: [
        {
          type: "stage",
          height: 100,
          width: 33,
          top: 0,
          left: 0,
          opacity: 1,
        },
        {
          type: "iframe1",
          webpage_url: "abc.com",
          height: 100,
          width: 67,
          top: 0,
          left: 33,
          opacity: 1,
        },
        {
          type: "iframe2",
          webpage_url: "abc.com",
          height: 100,
          width: 67,
          top: 0,
          left: 33,
          opacity: 1,
        },
        {
          type: "iframe3",
          webpage_url: "abc.com",
          height: 100,
          width: 67,
          top: 0,
          left: 33,
          opacity: 1,
        },
      ],
      mobile_landscape_layout: [
        {
          type: "stage",
          height: 100,
          width: 33,
          top: 0,
          left: 0,
          opacity: 1,
        },
        {
          type: "iframe1",
          webpage_url: "abc.com",
          height: 100,
          width: 67,
          top: 0,
          left: 33,
          opacity: 1,
        },
        {
          type: "iframe2",
          webpage_url: "abc.com",
          height: 100,
          width: 67,
          top: 0,
          left: 33,
          opacity: 1,
        },
        {
          type: "iframe3",
          webpage_url: "abc.com",
          height: 100,
          width: 67,
          top: 0,
          left: 33,
          opacity: 1,
        },
      ],
      key: "022e2f60-e333-47c5-8c48-0660561ba575",
    }
    let dataObj = JSON.stringify(data)

    let obj = {
      event: "extensionMessage",
      time: Date.now(),
      message: {
        data: dataObj,
        extension: extTokenAuth.extensionID,
        type: "open",
      },
    }

    // db.ref("extensions/" + room).push(obj)
  }, [])
  return <Extension />
}
export default App
